<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="title" :breadcrumbs="breadcrumbs"/>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <TheSectionDetail/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: "index",
  components: {
    TheSectionDetail: () =>
        import(
            "@/components/dashboardComponents/TheSectionDetail"
            ),
    BaseBreadcrumb: () =>
        import(
            "@/components/commonComponents/BaseBreadcrumb"
            ),
  },
  data () {
    return {
      section: {
        nombre: ''
      },
      title: 'Secciones',
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/dashboard/index/"
        },
        {
          text: "Sections",
          disabled: true,
          href: "/dashboard/simulacros/"
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['SET_SEARCH'])
  }

}
</script>

<style scoped>

</style>
